<template>
<div class="page">

  <var-app-bar title="账号找回" title-position="center" color="#ffffff" text-color="#000" :safe-area-top="true" >
    <template #left>
      <var-button
        color="transparent"
        text-color="#000"
        round
        text
        @click="() => router.go(-1)"
      >
        <var-icon name="chevron-left" :size="24" />
      </var-button>
    </template>
  </var-app-bar>
  <!-- 上传凭证 -->
  <div v-if="active == 0" class="w-full">
    <div class="tips">如果您未购买任何服务，请重新注册。</div>
    <div class="w">
      <div class="step">
        <var-steps :active="active" active-color="#2F88FF">
          <var-step>上传凭证</var-step>
          <var-step>确认账号</var-step>
          <var-step>等待审核</var-step>
          <var-step>完成</var-step>
        </var-steps>
      </div>
      <div class="upload-img">
        <div class="title">上传支付凭证</div>
        <div class="desc">确保您的支付订单截图展示出完成的支付时间</div>
        <var-loading description="LOADING" :loading="loading">
          <div class="wr">
            <div class="w1" @click="chooseImg">
              <img v-if="img1" :src="img1" alt="" style="width: 100%;height: 100%">
              <img v-else src="@/assets/upload-pic.png" style="width:24px;height: 24px" alt="">
            </div>
            <div class="t">支付截图</div>
          </div>
          <div class="time-w">
            <span class="span1">支付时间</span>
            <input type="datetime-local" placeholder="请选择支付时间" v-model="time">
            <img src="@/assets/ab.png" style="width:18px;height: 18px;" alt="">
          </div>
        </var-loading>
      </div>
      <div class="wrapper">
        <div class="title">工单结果查询</div>
        <div class="tips">如果您已提交过工单，请点击 <span @click="goCheck" style="color:#2F88FF">查看工单结果</span></div>
      </div>
      <var-loading description="uploading" :loading="loading">
        <div class="btn" @click="getAccountList">下一步</div>
      </var-loading>
    </div>
  </div>
  <!-- 确认账号 -->
  <div v-if="active == 1" class="w-full">
    <div class="step">
      <var-steps :active="active" active-color="#2F88FF">
        <var-step>上传凭证</var-step>
        <var-step>确认账号</var-step>
        <var-step>等待审核</var-step>
        <var-step>完成</var-step>
      </var-steps>
    </div>
    <div class="wrapper">
      <div class="title">确认账号</div>
      <div class="tips">请选择下面列表中，哪个是你使用的账号</div>
      <div class="list">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="user-name" @click="show = true">{{ item.name }}</div>
        </div>
        <div class="empty" v-if="!list.length">
          <div class="user-name">暂无账号</div>
        </div>
      </div>
    </div>
    <div class="btn" @click="inputUserName">下一步</div>

    <var-dialog 
      v-model:show="show"
      @confirm="handleConfirm"
      @cancel="() => Snackbar.error('cancel')">
    <template #title>
      <div class="title" style="text-align: center">请输入完整用户名</div>
    </template>
    <var-input placeholder="请输入完整用户名称" v-model="username" />
    </var-dialog>
  </div>
  <!-- 等待审核 -->
  <div v-if="active == 2" class="w-full">
    <div class="step">
      <var-steps :active="active" active-color="#2F88FF">
        <var-step>上传凭证</var-step>
        <var-step>确认账号</var-step>
        <var-step>等待审核</var-step>
        <var-step>完成</var-step>
      </var-steps>
    </div>
    <div class="wrapper flex-col" v-if="status == 0">
      <img src="@/assets/withing.png" style="width:40px;height: 40px;margin-bottom: 15px" alt="">
      <div class="title">资料已提交</div>
      <div class="tips">你的资料已经提交，请等待客户人员审核</div>
    </div>
    <div class="wrapper flex-col" v-if="status == 1">
      <img src="@/assets/suceess.png" style="width:40px;height: 40px;margin-bottom: 15px" alt="">
      <div class="title">审核成功</div>
      <div class="tips">您的账号为: {{ status == 1 ? username : '*****' }}</div>
    </div>
    <div class="wrapper flex-col" v-if="status == 2">
      <img src="@/assets/error.png" style="width:40px;height: 40px;margin-bottom: 15px" alt="">
      <div class="title">审核未通过</div>
      <div class="tips">失败原因：{{ res1 }}</div>
    </div>
    <div class="wrapper" v-if="status == 0">
      <div class="title">工单二维码</div>
      <div class="tips">请保存你的工单号，或者截图保存工单二维码</div>
      <div class="flex-col">
        <qrcode-vue :value="url1" :size="120"></qrcode-vue>
        <div class="tips">工单号：{{workId}}</div>
      </div>
    </div>
    <div class="wrapper" v-if="status == 1">
      <div class="title">推荐您绑定手机号或邮箱</div>
      <div class="tips">避免下次忘记用户名或密码</div>
      <div class="btn1">
        <img src="@/assets/email.png" style="width: 24px;height: 24px;" alt="">
        <span class="title">绑定邮箱并重置</span>
        <img src="@/assets/ra.png" style="width: 18px;height: 18px;" alt="">
      </div>
      <div class="btn1">
        <img src="@/assets/phone.png" style="width: 24px;height: 24px;" alt="">
        <span class="title">绑定邮箱并重置</span>
        <img src="@/assets/ra.png" style="width: 18px;height: 18px;" alt="">
      </div>
      <a class="btn1" :href="url"  >
        <img src="@/assets/noicon.png" style="width: 24px;height: 24px;" alt="">
        <span class="title">不绑定直接重置</span>
        <img src="@/assets/ra.png" style="width: 18px;height: 18px;" alt="">
      </a>
    </div>
    <div class="btn" v-if="status == 2" @click="refresh">返回重试</div>
  </div>
  <var-snackbar v-model:show="show1">这是一个消息条！！</var-snackbar>
  <input type="file" style="display: none" ref="input" @change="handleImgChange">
</div>
</template>

<script setup>
  import { ref, watchEffect, onUnmounted } from 'vue'
  import { Snackbar } from '@varlet/ui'
  import QrcodeVue from "qrcode.vue" // orderDetails
  import { createOrder, uploadFile ,accountList, orderDetails, resetPwdLink  } from "@/api/account"
  import { useRouter, useRoute } from 'vue-router'
  import Tesseract from 'tesseract.js';
  // 以下是一些支持 Vue 3 的移动端组件库：

  const router = useRouter()
  const route = useRoute()
  const appkey = ref(route.query.appkey)
  // const id = ref(route.query.id)

  const input = ref(null)
  const img1 = ref('')
  const workId = ref(route.query.id || '')
  const loading = ref(false)
  const res1 = ref('')
  // 上传图片
  const handleImgChange = (e) => {
    // console.log(e)
    loading.value = true
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = function() {
      img1.value = reader.result
      Tesseract.recognize(
      reader.result,
        'chi_sim',
        { logger: m => console.log(m) }
      ).then(({ data: { text } }) => {
        // console.log('Recognized Text:', text);
        const regex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
        const time2 = text.match(regex);
        if (time2 && time2[0]) {
          console.log('time:', time2[0]);
          time.value = time2[0]
        } else {
          const regex1 = /\d{4} 年 \d{1,2} 月 \d{1,2} 日 \d{2}:\d{2}:\d{2}/;
          const time1 = text.match(regex1);
          console.log('time1:', time1);
          if (time1) {
            console.log('time:', time1[0]);
            time.value = time1[0]
          } else {
            console.log('未识别到时间');
            Snackbar.error('未识别到时间,请手动输入支付时间')
          }
        } 
      }).catch(err => {
        console.error('OCR Error:', err);
        Snackbar.error('识别失败')
      });
    }
    reader.readAsDataURL(file)
    const formData = new FormData()
    formData.append('file', file)
    uploadFile(formData).then(res => {
      if (res && res.data) {
        img1.value = res.data
      } else {
        Snackbar.error('上传失败')
      }
      loading.value = false
      input.value.value = ''
    })
  }

  const active = ref(route.query.status || 0)
  const list = ref([
    {
      id: 1,
      name: '账号1'
    },
    {
      id: 2,
      name: '账号2'
    },
    {
      id: 3,
      name: '账号3'
    }
  ])
  const username = ref(route.query.username || '')
  const show = ref(false)
  const show1 = ref(false)
  const status = ref(0)
  const time = ref('')
  const time1 = ref(null)
  const key = ref('')

  const url1 = ref('')

  const createWorke = () => {
    const data = {
      appKey: appkey.value,
      describe: '描述',
      imgs: img1.value,
      pay_time: time.value,
      fullAccount: username.value,
      usersKey: key.value
    }
    createOrder(data).then(res => {
      if (res && res.data) {
        workId.value = res.data._id
        // console.log('工单创建成功', workId.value)
        url1.value = `${window.location.href}/#/account?id=${res.data._id}&username=${username.value}&status=2&appkey=${appkey.value}`
        console.log('工单创建成功', url1.value)
        time1.value = setInterval(() => {
          getOrderDetails()
        }, 5000)
      }
    })
  }

  const handleConfirm = () => {
    if (!username.value) {
      Snackbar.error('请输入完整用户名')
      return
    }
    show.value = false
    createWorke()
    Snackbar.success('confirm')
    next()
  }

  // 根据支付时间获取账号列表
  const getAccountList = () => {
    console.log('根据支付时间获取账号列表')
    if (!img1.value) {
      Snackbar.error('请先上传支付凭证')
      return
    }
    if (!time.value) {
      Snackbar.error('请先选择支付时间')
      return
    }
    const data = {
      pay_time: time.value
    }
    accountList(data).then(res => {
      if (res && res.data) {
        key.value = res.data.usersKey || 'key'
        list.value = res.data.map(item => {
          const str = item
          const replaced = str.replace(/.(?=.*.)/g, (c, i) => i > 0 ? '*' : c);
          return {
            id: item,
            name: replaced
          }
        })
        next()
      } else {
        Snackbar.error('获取账号列表失败')
      }
     
    }).catch(err => {
      console.log(err)
    })
  }

  // 去查看工单结果
  const goCheck = () => {
    router.push(`/result?appkey=${appkey.value}`)
  }

  // 选择图片
  const chooseImg = () => {
    input.value.click()
  }
  
  // 确认用户名
  const inputUserName = () => {

    show.value = true
    
  }

  function next() {
    active.value = (active.value + 1) % 4
  }

  // 获取工单详情
  const getOrderDetails = () => {
    orderDetails({
      _id: workId.value
    }).then(res => {
      // console.log(res)
      if (res && res.data) {
        username.value = res.data.fullAccount
        // 审核通过
        if (res.data.status == 3) {
          clearInterval(time1.value)
          status.value = 1
          getResetPwdLink()
          // next()
          // 待审核
        } else if (res.data.status == 2) {
          // clearInterval(time1.value)
          status.value = 0
        } else {
          // 审核未通过
          clearInterval(time1.value)
          if (res.data.flowPath && res.data.flowPath.length > 0) {
            const len = res.data.flowPath.length
            const str =  res.data.flowPath[len - 1].extendData || ''
            if (str) {
              try {
                const obj = JSON.parse(str) || {}
                res1.value = obj.describe
              } catch (e) {
                console.log(e)
                res1.value = '未知错误'
              }
            }
          }
          status.value = 2
        }
      }
    })
  }


  watchEffect(() => {
    if (workId.value) {
      time1.value = setInterval(() => {
          getOrderDetails()
        }, 5000)
    }
  })

  onUnmounted(() => {
    clearInterval(time1.value)
  })

  // 获取重置密码连接
  const url = ref('')
  const getResetPwdLink = () => {
    resetPwdLink({
      orderId: workId.value,
      username: username.value
    }).then(res => {
      if (res && res.data) {
        // window.open(res.data)
        url.value = res.data
      }
    })
  }

  // 点击刷新当前页面
  const refresh = () => {
    window.location.reload()
  }
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
 
  background-color: #F7F8FA;
}
.tips {
  font-size: 12px;
  font-weight: 400;
  color: #868E9E;
  text-align: left;
  box-sizing: border-box;
  padding: 15px 15px;
}
.w {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
}
.step {
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 15px;
}
.upload-img {
  margin: 20px auto 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-align: left;
    box-sizing: border-box;
    height: 20px;
  }
  .desc {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #868E9E;
    text-align: left;
    box-sizing: border-box;
    height: 20px;
  }
  .wr {
    margin-top: 15px;
    margin-right: calc(100%-165px);
    width: 165px;
    height: 145px;
    background-color: #F7F8FA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .w1 {
      width: 115px;
      height: 80px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .t {
      margin-top: 10px;
      color: #83909D;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
  .time-w {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    .span1 {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: left;
      box-sizing: border-box;
      height: 20px;
      width: 65px;
    }
    input {
      flex: 1;
      width: 70%;
      height: 40px;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: left;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
  }
}
.wrapper {
  margin: 20px auto 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
}
.btn {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background-color: #2F88FF;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  color: #fff;
}

.w-full {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
}

.list {
  display: flex;
  flex-direction: column;
  .item {
    width: 100%;
    height: 38px;
    background-color: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    box-sizing: border-box;
    padding: 0 30px;
  }

}

.user-name {
  color: #1C212A;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn1 {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: #F8F9FA;
  height: 54px;
  border-radius: 8px;
  padding: 0 15px;
  text-decoration: none;
  color: #333333;
  .title {
    flex: 1;
  }
}
</style>
