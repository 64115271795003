import service from "@/utils/ajax"

// 创建工单
export const createOrder = (data)=>{
    return service({
        url:"/v1/workOrder/accountRetrievalWorkOrder",
        method:"POST",
        data
    })
}

// 更新工单
export const updateOrder = (data)=>{
    return service({
        url:"/v1/workOrder/updateAccountRetrievalWorkOrder",
        method:"POST",
        data
    })
}

// 获取工单详情
export const orderDetails = (params)=>{
    return service({
        url:"/v1/workOrder/details",
        method:"GET",
        params
    })
}

// 获取重置密码连接
export const resetPwdLink = (data)=>{
    return service({
        url:"/v1/accountRetrieval/getResetPwd",
        method:"GET",
        params: data
    })
}

// 获取账号列表
export const accountList = (params)=>{
    return service({
        url:"/v1/accountRetrieval/getusersbypaytime",
        method:"GET",
        params
    })
}

// 文件上传
export const uploadFile = (data)=>{
    return service({
        url:"/v1/api/uploadFile",
        method:"POST",
        headers:{
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

// 获取验证码
export const getCode = (params)=>{
    return service({
        url:"/v1/captcha/getCaptcha",
        method:"GET",
        params
    })
}

// 校验验证码
export const checkCode = (data)=>{
    return service({
        url:"/v1/captcha/verifyCaptcha",
        method:"POST",
        data
    })
}
